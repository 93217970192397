:root {
  --bg-color: #FDF1CE;
}

html, body {
  height: 100vh;
  background: black;
  font-family: sans-serif;
  padding: 0;
  margin: 0;
}

#container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: grey;
}

textarea {
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  font-size: 3vmax;
  border: none;
  box-sizing: border-box;
  white-space: pre-wrap;
  flex:1;
  background-color: #FDF1CE;
}

button {
  position: fixed;
  font-size: 3vmax;
  font-weight: bold;
  padding: 1vmax;
  margin: 1vmax;
  right: 0;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  z-index: 10;
  cursor: pointer;
}

button:hover {
  background-color: #FDF10E;
}

#Info {
  box-sizing: border-box;
  padding: 5vmax;
  background-color: white;
  overflow: auto;
  font-size: 3vmax;
  background-color: #FDF1CE;
  opacity: 0.95;
}

dl {
  display: grid;
  grid-template-columns: 3em 1fr;
}

dd {
  margin: 0;
  padding: 0 1em 0 0;
  min-width: 1em;
  position: relative;
}

dd:after {
  content: "";
  display: block;
  position: absolute;
  top: -.5em;
  left: -.5em;
  width: 2em;
  height: 2em;
  background-image: radial-gradient(#FDF10E66 1%, transparent 70%);
  background-size: 2em 2em;
  background-position: 0 0;
  background-repeat: no-repeat;
  z-index: -1;
}

dt {
  margin-bottom: 1em;
}

img {
  max-width: 100%;
}

.simple-keyboard {
  
}

#Tabs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#Tabs .tabs  {
  display: flex;
  padding: 10px 0 0 0 ;
  gap: 10px;
  font-size: 3vmax;
}

#Tabs input {
  display: none;
}

#Tabs .tabs .tab {
  flex:1;
  background-color: #FDF1CE;
  border: 1px solid #444;
  border-bottom: none;
  padding: 12px 10px 10px 20px;
  border-radius: 10px 10px 0 0;
}

#Tabs .tabs .tab[data-active=false] {
  /* darker than #FDF1CE; */
  background-color: #c6bea3;
  color: #4446;
  cursor: pointer;
}

#Tabs .tab-content {
  display: flex;
  flex:1;
  overflow: auto;
  overflow: hidden;
}

#Tabs .tab-content[data-active=false] {
  display: none;
}

#Editor {
  flex:1;
  display: flex;
  flex-direction: column;
}